import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import nurseService from "../../utils/api/v1/nurseService";

// typings
import { NurseName } from "../../utils/interfaces/Nurse";

// components
import PrivateRoute from "../../components/Authentication/PrivateRoute";
import LoadingError from "../../components/Loaders/LoadingError";
import Flex from "../../components/Containers/Flex";
import NurseCard from "../../components/Nurses/NurseCard";
import Button from "@material-ui/core/Button";
import CardContainer from "../../components/Containers/CardContainer";
import { Grid, TextField } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

const WorkersList = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchedInactive, setFetchedInactive] = useState<boolean>(false);
  const [showingActive, setShowingActive] = useState<boolean>(true);
  const [error, setError] = useState<any>({});
  const [workers, setWorkers] = useState<NurseName[]>([]);
  const [inactiveWorkers, setInactiveWorkers] = useState<NurseName[]>([]);
  const [visibleWorkers, setVisibleWorkers] = useState<NurseName[]>([]);

  const handlerOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase().trim();
    const currentWorkers = showingActive ? workers : inactiveWorkers;
    if (value === "") {
      setVisibleWorkers(currentWorkers);
      return;
    }
    const filteredWorkers = currentWorkers.filter((worker: NurseName) => {
      const targetProp = `${worker.names} ${worker.last_names}`;
      return targetProp
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(value);
    });
    setVisibleWorkers(filteredWorkers);
  };

  const fetchActiveData = async (): Promise<void> => {
    setLoading(true);

    try {
      const req = await nurseService.fetchActiveNurses();
      setWorkers(req.data.data);
      setVisibleWorkers(req.data.data);
    } catch (err) {
      console.log(err);
      setError(err);
    }

    setLoading(false);
  };

  const fetchInactiveData = async (): Promise<void> => {
    setLoading(true);

    try {
      const req = await nurseService.fetchInactiveNurses();
      setFetchedInactive(true);
      setInactiveWorkers(req.data.data);
      setVisibleWorkers(req.data.data);
    } catch (err) {
      console.log(err);
      setError(err);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchActiveData();
  }, []);

  useEffect(() => {
    if (!showingActive) {
      if (!fetchedInactive) {
        fetchInactiveData();
      } else {
        setVisibleWorkers(inactiveWorkers);
      }
    } else {
      setVisibleWorkers(workers);
    }
  }, [showingActive]);

  return (
    <PrivateRoute>
      <LoadingError loading={loading} error={error} />
      {!loading && (
        <>
          <Flex align="center" justify="space-between" margin="0 0 2rem 0">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h1>Health team members</h1>
              </Grid>
              <Grid item xs={12} sm={5} md={3} lg={3}>
                <Link
                  to="/health-team/announcements"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    fullWidth
                    variant="outlined"
                    size="large"
                    color="primary"
                  >
                    Anuncios
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3} justifyContent="space-between">
                  <Grid item xs={12} sm={6} md={5} lg={3}>
                    <TextField
                      fullWidth
                      label="Nombre"
                      variant="standard"
                      onChange={handlerOnChange}
                    />
                  </Grid>
                  <Grid item>
                    <FormControl>
                      <InputLabel id="fonasa">Estado</InputLabel>
                      <Select
                        disabled={loading}
                        value={`${showingActive}`}
                        onChange={(e) => {
                          setShowingActive(e.target.value === "true");
                        }}
                        fullWidth
                      >
                        <MenuItem key="active" value="true">
                          Activo
                        </MenuItem>
                        <MenuItem key="inactive" value="false">
                          Inactivo
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={5} md={3} lg={3}>
                    <Link
                      to="/health-team/new"
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        fullWidth
                        variant="outlined"
                        size="large"
                        color="primary"
                      >
                        Crear nuevo
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Flex>
          <CardContainer cols={4}>
            {visibleWorkers.map((worker: NurseName, index: number) => (
              <NurseCard
                key={index}
                names={`${worker.names} ${worker.last_names}`}
                picture={worker.profile_picture}
                id={worker.id}
              />
            ))}
          </CardContainer>
        </>
      )}
    </PrivateRoute>
  );
};

export default WorkersList;
